import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import CenteredCarousel from "../components/carousel/CenteredCarousel"
import InsightCard from "../components/Cards/InsightCard"
import { Button } from "../components/Button"
import AccordionBase from "../components/accordion/AccordionBase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown"

const SingleDoctor = ({ pageContext, location, data }) => {
  const post = data.allDoctorsJson.nodes[0]
  const latestBlogs = data.latestBlogs.nodes
  const { language, metaDescription, metaTitle } = pageContext

  return (
    <Layout
      language={language}
      navSpacer={!post.hasProfileImage}
      className="doctor-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <Section className="doctor-page__about-container relative">
        <Columns sideColumnsSize={1} className="doctor-page__columns">
          <div className="column doctor-page__header">
            <div className="doctor-page__heading">
              <Text className="px-40--mobile" as="h1" text={post.heading} />
              <Text as="p" text={post.blurb} />
            </div>
            <div className="doctor-page__doctor-logo-container">
              <div className="doctor-page__fan">
                <Image
                  wrapperClassName="doctor-page__image"
                  publicId="77P/DEV/doctor-fan-bio"
                />
              </div>
              <div className="column home__about-images">
                <div className="home__doctor--logos doctor-page__logos">
                  <Image
                    useAR={false}
                    publicId="77P/DEV/harvard-university"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                  />
                  <Image
                    useAR={false}
                    publicId="77P/DEV/UCSf"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  />
                  <Image
                    useAR={false}
                    publicId="77P/DEV/castle_connolly"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                    data-aos-delay="400"
                  />
                  <Image
                    useAR={false}
                    publicId="77P/DEV/ASPS_Member"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                    data-aos-delay="600"
                  />
                  <Image
                    useAR={false}
                    publicId="77P/DEV/realself-top-doctor"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                    data-aos-delay="800"
                  />
                </div>
              </div>
            </div>
          </div>
        </Columns>
        <Section className="doctor-page__content">
          <Columns sideColumnsSize={1}>
            <div className="column doctor-page__about">
              <Text useStringReplace text={post.aboutSection.body} />
            </div>
            <div className="column is-2"></div>
            <div className="column doctor-page__education">
              {/** For desktop view */}
              <div className="doctor-page__facts-list">
                {post.facts.map(({ fact }, i) => (
                  <>
                    <Text
                      as="h3"
                      text={fact.heading}
                      className={i > 0 ? "mt-2" : ""}
                    />
                    <Text
                      className="mt-2"
                      useStringReplace
                      key={i}
                      text={fact.text}
                    />
                  </>
                ))}
              </div>
              {/** For mobile view */}
              <div className="about-us__reasons doctor-page__facts-accordion">
                {post.facts.map(({ fact }, i) => {
                  return (
                    <FactsAccordion
                      key={fact.heading}
                      heading={fact.heading}
                      blurb={fact.text}
                    />
                  )
                })}
              </div>
            </div>
          </Columns>
        </Section>
      </Section>
      <Section zeroBottomMobile className="home-insight">
        <Columns sideColumnsSize={1}>
          <div className="column">
            <h2 className="has-text-centered mb-1">
              Explore Our Latest Insights
            </h2>
            <CenteredCarousel>
              {latestBlogs.map((blog, idx) => (
                <InsightCard
                  className={`card-${idx + 1}`}
                  key={blog.heading}
                  image={blog.hero}
                  heading={blog.heading}
                  href={`/${blog.title}`}
                  button={{
                    appearance: "black",
                    href: `/${blog.title}`,
                    destination: "internal",
                    buttonText: "Checkout The Blog"
                  }}
                />
              ))}
            </CenteredCarousel>
          </div>
        </Columns>
      </Section>
      <Section zeroBottom zeroTopMobile>
        <Columns sideColumnsSize={1} className="doctor-page__contact">
          <div className="column doctor-page__cta mb-2">
            <Text as="p" text={"Reach out for expert care"} />
            <Button
              buttonText="CONTACT US"
              href="/contact-us/"
              appearance="contained"
            />
          </div>
          <div className="column">
            <div className="column">
              <Image publicId="77P/DEV/reach-out-to-77-plastics" />
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

const FactsAccordion = ({ heading, blurb }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`about-us__reasons--accordion ${isOpen ? "open" : ""}`}>
      <button onClick={() => setIsOpen(!isOpen)} className="reset">
        <h3 className="px-0">{heading}</h3>
        <FontAwesomeIcon
          style={{
            transition: "all ease-in-out .3s",
            transform: isOpen ? "rotate(180deg)" : ""
          }}
          icon={faChevronDown}
        />
      </button>
      <AccordionBase isOpen={isOpen}>
        <Text
          style={{ paddingTop: "16px" }}
          className="px-0"
          as="p"
          text={blurb}
        />
      </AccordionBase>
    </div>
  )
}

export const pageQuery = graphql`
  query doctorPages($title: String!) {
    backButton: allUniquePagesJson(filter: { template: { eq: "about-us" } }) {
      nodes {
        title
      }
    }
    latestBlogs: allBlogsJson(
      sort: { fields: publishedDate, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        heading
        hero
        publishedDate
      }
    }
    allDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        hasProfileImage
        badges {
          hasBadges
          logos
        }
        blurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        facts {
          fact {
            heading
            text
            imageLeft
            image
          }
        }
      }
    }
  }
`

SingleDoctor.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({
    allDoctorsJson: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          heading: PropTypes.string,
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string,
          hasImages: PropTypes.bool
        })
      ).isRequired
    }).isRequired
  }).isRequired
}

export default SingleDoctor
